import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { H2 } from '@blueprintjs/core'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import '../style/about.scss'

const about = `
    I am a product development expert with several years experience in In-Vitro Diagnostics, Product Design, Software Development and Technical Problem Solving.
    I have a deep understanding of the fundamental sciences and a unique ability to solve technical problems with out-of-the-box yet practical solutions.
    I'm an expert troubleshooter who knows how to reframe design problems into simple and understandable subsystems.
    I'm comfortable working across domains with my broad skill set to navigate problems with deep complexity.
    As a naturally curious person, I'm not satisfied until I understand everything about the products I work on.
`

const About = () => (
    <Layout page='/about'>
        <SEO title='About' />
        <div className='About'>
            <H2>About</H2>
            <StaticImage
                className='profile'
                src='../images/profile-side.jpg'
                alt=''
                loading='eager'
                placeholder='blurred'
            />
            <p>{about}</p>
        </div>
    </Layout>
)

export default About
